import React from "react";
import Link from "./link";

const Button = ({
  type,
  url = false,
  label,
  target = "_self",
  shadow = false,
  externalURL = false,
  large = false,
  medium = false,
  isForm = false,
  classNames = false,
  linkClassNames = false,
  id = null,
  onClick = false,
  promo = false,
  align = false
}) => {
  const createClassList = () => {
    let classList = "ca-button ca-button--" + type;
    if (shadow) classList = classList + " ca-button--shadow";
    if (large) classList = classList + " ca-button--large";
    if (medium) classList = classList + " ca-button--medium";
    if (isForm) classList = classList + " ca-button--form";
    if (align) classList = classList + " ca-button--" + align;
    if (classNames) classList = classList + " " + classNames;
    return classList;
  };

  const linkComponent = (
    <Link
      id={id}
      url={url}
      target={target}
      external={externalURL}
      promo={promo}
      onClick={onClick}
      classNames={linkClassNames}
    >
      {label}
    </Link>
  );

  return <div className={createClassList()}>{linkComponent}</div>;
};

export default Button;
